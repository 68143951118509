import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import {
  useBreakpoint,
  Center,
  chakra,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"

const styles = {
  div: {
    cursor: "pointer",
  },

  icon: {
    boxSize: "2em",
  },

  image: {
    borderRadius: "full",
    border: "7px solid #3399FF",
    height: "177px",
    width: "176px",
    mt: "80px",
  },
  modalBody: {
    borderBottom: "1px solid #E0E0E0",
    padding: "0px",
    overflowX: "hidden",
    overflowY: "scroll",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  modalContent: {
    width: "100%",
    px: "50px",
    maxW: { base: "100%", md: "100%", lg: "50%" },
    maxH: "100vh",
    minH: "100vh",
    left: "100%",
    top: "0%",
    marginTop: "0px",
  },

  modalText: {
    color: "#337EDC",
  },

  modalTitle: {
    fontSize: "2xl",
    fontWeight: "bold",
    textAlign: { base: "center", md: "left", lg: "left" },
  },
}

const ReadAdvisorBio = props => {
  const bp = useBreakpoint()

  if (!props.advisor) {
    return null
  }

  const isMobile = ["sm", "md"].includes(bp)

  return (
    <Modal
      isCentered={false}
      isOpen={props.isOpen}
      onClose={props.onClose}
      motionPreset="slideInRight"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent sx={styles.modalContent}>
        <ModalCloseButton />

        <ModalBody sx={styles.modalBody}>
          <Center>
            <Image
              sx={styles.image}
              alt={props.advisor.name}
              as={GatsbyImage}
              image={props.advisor.image.gatsbyImageData}
            />
          </Center>

          <chakra.div
            sx={{
              paddingTop: "2em",
              marginBottom: "1em",
            }}
          >
            <Text sx={styles.modalTitle}>{props.advisor.name}</Text>
          </chakra.div>

          <chakra.div sx={{ marginBottom: "25px" }}>
            <Text
              dangerouslySetInnerHTML={{
                __html: props?.advisor?.shortBio?.childMarkdownRemark?.html,
              }}
            />
          </chakra.div>
        </ModalBody>

        <ModalFooter
          sx={{ padding: "0px", marginTop: "55px", marginBottom: "100px" }}
        >
          <Flex
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "2em",
            }}
          >
            <chakra.div
              sx={styles.div}
              onClick={() => props.onPrevious(props.advisor)}
            >
              <Text sx={styles.modalText}>
                <ChevronLeftIcon sx={styles.icon} />
                Previous{!isMobile && ": " + props?.advisor?.previous?.name}
              </Text>
            </chakra.div>
            <chakra.div
              sx={styles.div}
              onClick={() => props.onNext(props.advisor)}
            >
              <Text sx={styles.modalText}>
                Next{!isMobile && ": " + props?.advisor?.next?.name}
                <ChevronRightIcon sx={styles.icon} />
              </Text>
            </chakra.div>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ReadAdvisorBio
