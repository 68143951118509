import React from "react"
import { Box, Grid, GridItem, Heading, Link } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"

const styles = {
  root: {
    textAlign: "center",
    marginBottom: "120px",
  },
  "logo-box": {
    vertialAlign: "center",
    padding: "24px",
  },
  flex: {
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    flexDirection: { base: "column", md: "column", lg: "row" },
  },
  heading: {
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    fontWeight: 700,
    lineHeight: "48px",
    marginBottom: "60.5px",
  },
}

const AboutInvestors = () => {
  return (
    <Box sx={styles.root}>
      <Heading sx={styles.heading}>Our Investors</Heading>
      <Grid
        justifyItems="center"
        alignItems="center"
        templateColumns={{
          base: "repeat(2, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
        }}
        width={{ base: "100%", md: "100%", lg: "769px" }}
        margin="0 auto"
        px={{ base: "24px", md: "24px", lg: "40px" }}
        marginBottom="80px"
      >
        <GridItem sx={styles["logo-box"]}>
          <Link href="https://www.foundercollective.com/" isExternal>
            <StaticImage
              src="../../images/about-us/investor-founder.png"
              alt="Founder Collective"
              sx={styles["logo-box"]}
            />
          </Link>
        </GridItem>
        <GridItem sx={styles["logo-box"]}>
          <Link href="https://www.fulcrumep.com/" isExternal>
            <StaticImage
              src="../../images/about-us/investor-fulcrum.png"
              alt="Fulcrum Equity Partners"
              sx={styles["logo-box"]}
            />
          </Link>
        </GridItem>

        <GridItem sx={styles["logo-box"]}>
          <Link href="https://www.generalcatalyst.com/" isExternal>
            <StaticImage
              src="../../images/about-us/investor-general.png"
              alt="General Catalyst"
              sx={styles["logo-box"]}
            />
          </Link>
        </GridItem>

        <GridItem sx={styles["logo-box"]}>
          <Link href="https://rothmanortho.com/" isExternal>
            <StaticImage
              src="../../images/about-us/investor-rothman.png"
              alt="Rothman Orthopedics"
              sx={styles["logo-box"]}
            />
          </Link>
        </GridItem>

        <GridItem sx={styles["logo-box"]}>
          <Link href="https://www.spartagroupllc.com/" isExternal>
            <StaticImage
              src="../../images/about-us/investor-sparta.png"
              alt="Sparta Group"
              sx={styles["logo-box"]}
            />
          </Link>
        </GridItem>

        <GridItem sx={styles["logo-box"]}>
          <Link href="https://tectonicventures.com/" isExternal>
            <StaticImage
              src="../../images/about-us/investor-tectonic.png"
              alt="Tectonic Ventures"
              sx={styles["logo-box"]}
            />
          </Link>
        </GridItem>
      </Grid>
    </Box>
  )
}

export default AboutInvestors
