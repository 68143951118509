import React from "react"
import { Grid, GridItem, Heading } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Hero from "../components/about-us/hero"
import AboutUs from "../components/about-us/about-us"
import AboutVideo from "../components/about-us/about-video"
import AboutContent from "../components/about-us/about-content"
import AboutInvestors from "../components/about-us/about-investors"
import AboutAdvisors from "../components/about-us/about-advisors"
import ReadAdvisorBio from "../components/about-us/advisor-bio"

const styles = {
  heading: {
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    fontWeight: 700,
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    marginBottom: "60.5px",
    textAlign: "center",
  },
}

const getPrevious = (advisors, index) =>
  index > 0 ? advisors[index - 1] : advisors[advisors?.length - 1]

const getNext = (advisors, index) =>
  index < advisors?.length - 1 ? advisors[index + 1] : advisors[0]

const AboutUsIndex = props => {
  const advisors = props.data.allContentfulAdvisor.nodes.map(
    (advisor, index) => ({
      ...advisor,
      previous: getPrevious(props.data.allContentfulAdvisor.nodes, index),
      next: getNext(props.data.allContentfulAdvisor.nodes, index),
      index,
    })
  )

  const [advisor, setAdvisor] = React.useState()

  const handleClickPerson = payload => {
    setAdvisor(payload)
  }

  const handleClose = () => {
    setAdvisor(undefined)
  }

  const handlePrevious = React.useCallback(
    payload => {
      const current = getPrevious(advisors, payload.index)
      const previous = getPrevious(advisors, current.index)
      const next = getNext(advisors, current.index)
      setAdvisor({
        ...current,
        previous,
        next,
      })
    },
    [advisors]
  )
  const handleNext = React.useCallback(
    payload => {
      const current = getNext(advisors, payload.index)
      const previous = getPrevious(advisors, current.index)
      const next = getNext(advisors, current.index)
      setAdvisor({
        ...current,
        previous,
        next,
      })
    },
    [advisors]
  )
  return (
    <Layout location={props.location} title={"About Us"}>
      <Hero
        title="About Us"
        Image={() => (
          <StaticImage
            src="../images/about-us/about-us.png"
            placeholder="blurred"
            alt="About Us Image"
          />
        )}
      />
      <AboutUs />
      <AboutVideo />
      <AboutContent />
      <AboutInvestors />
      <Heading sx={styles.heading}>Our Advisors</Heading>

      <Grid
        justifyItems="center"
        templateColumns={{
          base: "repeat(2, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(6, 1fr)",
        }}
        gap={6}
        width="100%"
        margin="0 auto"
        px={{ base: "24px", md: "24px", lg: "40px" }}
        marginBottom="80px"
      >
        {advisors.map(advisor => (
          <GridItem rowSpan={2}>
            <AboutAdvisors
              {...advisor}
              key={advisor.id}
              onClick={handleClickPerson}
            />
          </GridItem>
        ))}
      </Grid>
      <ReadAdvisorBio
        isOpen={!!advisor}
        advisor={advisor}
        onClose={handleClose}
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
    </Layout>
  )
}

export default AboutUsIndex
export const query = graphql`
  query AboutUsIndexQuery {
    allContentfulAdvisor(sort: { fields: [name], order: ASC }) {
      nodes {
        name
        id
        image {
          gatsbyImageData(placeholder: BLURRED, width: 217, height: 203)
          id
        }
        shortBio {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
