import React from "react"
import { Flex, Heading, Box } from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"

const styles = {
  root: {
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    alignItems: "center",
    width: "100%",
    height: { base: "100%", md: "100%", lg: "481px" },
    px: { base: "0px", md: "0px", lg: "10%" },
    justifyContent: "center",
  },
  "left-box": {
    width: { base: "100%", md: "100%", lg: "256px" },
    flexDirection: "column",
    justifyContent: "center",
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    px: { base: "24px", md: "24px", lg: "0px" },
    mt: { base: "40px", md: "40px", lg: "none" },
  },
  heading: {
    color: "#1B263F",
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "48px" },
    lineHeight: { base: "32px", md: "32px", lg: "56px" },
    textAlign: "left",
  },
  image: {
    textAlign: "center",
    ml: { base: "0px", md: "0px", lg: "26px" },
    mt: { base: "40px", md: "40px", lg: "0px" },
    px: { base: "24px", md: "24px", lg: "0px" },
    height: { base: "auto", md: "auto", lg: "300px" },
    width: { base: "100%", md: "100%", lg: "500px" },
  },
  icon: {
    color: "#9CACBC",
    fontSize: "42px",
    marginTop: "32px",
    display: { base: "flex", md: "flex", lg: "none" },
  },
}

const Hero = ({ Image, title }) => (
  <Flex sx={styles.root}>
    <ChevronDownIcon sx={styles.icon} />
    <Flex sx={styles["left-box"]}>
      <Heading sx={styles.heading}>{title}</Heading>
    </Flex>
    {Image && (
      <Box sx={styles.image}>
        <Image />
      </Box>
    )}
  </Flex>
)

export default Hero
