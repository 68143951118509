import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { chakra, Box, Text, Image } from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"

const styles = {
  root: {
    textAlign: "center",
  },
  image: {
    marginBottom: "24px",
  },
  text: {
    color: "#129459",
    fontWeight: "bold",
    marginBottom: "16px",
  },
  button: {
    cursor: "pointer",
  },
}

const AboutAdvisors = props => {
  return (
    <Box sx={styles.root}>
      <Image
        as={GatsbyImage}
        alt={props.name}
        image={props.image.gatsbyImageData}
        sx={styles.image}
      />
      <Text sx={styles.text}>{props.name}</Text>
      <chakra.div sx={styles.button} onClick={() => props.onClick(props)}>
        <Text fontSize="sm" marginTop="1em" color="#3399FF">
          read more about {props.name}
          <ChevronDownIcon boxSize="1.5em" marginLeft=".3em" />
        </Text>
      </chakra.div>
    </Box>
  )
}

export default AboutAdvisors
