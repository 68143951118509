import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"

const styles = {
  root: {
    mb: "100px",
    mt: { base: "40px", md: "40px", lg: "none" },
    px: "24px",
    width: "100%",
  },
  "inner-box": {
    width: { base: "100%", md: "100%", lg: "60%" },
  },
  flex: {
    alignItems: "center",
    justifyContent: "center",
  },
  "heading-text": {
    fontWeight: "bold",
    fontSize: { base: "16px", md: "16px", lg: "18px" },
  },

  text: {
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
  },
}

const AboutUs = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles.flex}>
        <Box sx={styles["inner-box"]}>
          <Text
            sx={{ fontWeight: "bold", lineHeight: "150%", fontSize: "18px" }}
          >
            With Avant-garde Health, healthcare providers finally have the level
            of detail needed to see opportunites for improvement they couldn't
            see before.
          </Text>
          <Text sx={{ mb: "15px" }}></Text>
          <Text>
            Those details include the cost of supplies, OR times and many others
            - across physicians, departments, hospitals and even other
            providers. For too long, administrators and clinicans have had more
            data than they knew what to do with, but still no way to spot all
            the factors that impact clinical outcomes and costs.
          </Text>
          <Text sx={{ mb: "15px" }}></Text>
          <Text>
            That's why its been so hard for everyone to figure out - much less
            agree on - where to make changes. It's time to take a closer look at
            everything. And that means providers need a way to see the clincial,
            finanacial, and operational detail they couldn't see before, compare
            the findings across deparments, and even other health systems and
            develop the playbooks to turn all these insights into action steps.
          </Text>
          <Text sx={{ mb: "15px" }}></Text>
          <Text>
            Only Avant-garde Health brings this approach. That's why everyone
            from Mass General to Hershey Medical Center to Gunderson Health
            System all rely on us. And it's how we've helped our clients achieve
            an average $2,300 reduction in cost per case.
          </Text>
        </Box>
      </Flex>
    </Box>
  )
}

export default AboutUs
